<template>
	<v-dialog
		:value="value"
		@input="$emit('input')"
		max-width="400"
		:content-class="'set-you-album-price-dialog'">
		<!--        <button class="close_modal" @click="closeDialog"></button>-->
		<div class="head">
			<h2 v-if="setAlbumYourPriceModalVariant === 'set'">Set Your Price</h2>
			<h2 v-if="setAlbumYourPriceModalVariant === 'buy'">Buy Now</h2>
		</div>
		<div class="content_dialog">
			<div class="album_description">
				<div class="album_cover">
					<img :src="album.smallImage" alt="">
				</div>
				<div class="album_info">
					<div class="title_album">
						<template v-if="type === 'music_release'">{{ album.title }}</template>
						<template v-if="type === 'song'">{{ song.title }}</template>
					</div>
					<div class="artist_name"><span>by</span>{{ album.artist.name }}</div>
				</div>
			</div>
			<div class="box_price">
				<div class="label_input">Enter amount:</div>
				<div class="price_input" :class="{err: priceError && price && price.length}">
					<span class="label_currency">$</span>
					<input type="text" v-model="price" required v-on:keypress="numbersOnly" ref="price"> <!-- autofocus -->
				</div>
				<template v-if="type === 'music_release'">
					<div class="hint_text" v-if="!album.price || album.price === 0">No minimum</div>
					<div class="hint_text" v-if="album.price > 0">${{ album.price }} USD or more</div>
				</template>
				<template v-if="type === 'song'">
					<div class="hint_text" v-if="!song.price || song.price === 0">No minimum</div>
					<div class="hint_text" v-if="song.price > 0">${{ song.price }} USD or more</div>
				</template>
			</div>

			<div class="follow_box" @click="toggleFollow" :class="{active: showFollowBox}">
				<div class="check_icon"></div>
				<div class="follow_description">
					<div class="follow_artist">Follow {{ album.artist.name }}</div>
					<div class="check_description">Get notifications about new releases</div>
				</div>
			</div>
		</div>

		<div class="actions_dialog"> <!--  add_to_cart_btn -->
			<template
				v-if="(type === 'music_release' && album.price && album.price > 0 || !price ||price > 0) || (type === 'song' && song.price > 0 || !price || price > 0)">
				<button type="button" class="primary_btn" @click="addToCart('add_to_cart')" :class="{disabled: priceError, 'loading': addToCartLoader}">
					<v-progress-circular :width="2" indeterminate></v-progress-circular>
					Add to Cart
				</button>
				<button type="button" class="primary_btn" @click="addToCart('buy_now')" :class="{disabled: priceError, 'loading': buyNowLoader}">
					<v-progress-circular :width="2" indeterminate></v-progress-circular>
					Checkout
					<span class="arrow" v-if="!priceError">
	                    <span class="line_svg"></span>
	                    <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
	                        <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
	                    </svg>
					</span>
				</button>
			</template>
			<template v-else>
				<button type="button" class="download primary_btn" @click="download" :class="{'loading': downloadLoader}">
					<v-progress-circular :width="2" indeterminate></v-progress-circular>
					Download
				</button>
			</template>
		</div>
		<errorDialog v-if="errorDialog" v-model="errorDialog"></errorDialog>

		<v-dialog
			v-if="canPurchaseMusicReleaseDialog"
			v-model="canPurchaseMusicReleaseDialog"
			max-width="500">
			<div class="style_dialog error_dialog">
				<h2 class="header">
					Oops!
				</h2>
				<div class="cnt">
					This artist is having issues receiving payments<br>
					Try again later
				</div>
				<div class="wrap_actions center">
					<button type="button" class="tetriatary_btn" @click="canPurchaseMusicReleaseDialog = false" v-ripple>Ok</button>
				</div>
			</div>
		</v-dialog>

	</v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import errorDialog from '@/components/dialogs/error.vue';

export default {
	name: "setYouAlbumPrice",
	props: {
		value: {
			type: Boolean
		},
		type: String,
		album: Object,
		song: Object,
		setAlbumYourPriceModalVariant: {
			type: String,
			required: true
		}
	},
	components: {
		errorDialog
	},
	data() {
		return {
			price: null,
			priceError: true,
			allowActions: false,
			addToCartLoader: false,
			buyNowLoader: false,
			downloadLoader: false,
			isFollowing: true,
			showFollowBox: true,
			errorDialog: false,
			canPurchaseMusicRelease: false,
			canPurchaseMusicReleaseDialog: false
		}
	},
	watch: {
		price() {
			if (this.type === 'music_release') {
				this.priceError = this.price > 1000 || !this.price || this.price < this.album.price;
			}
			if (this.type === 'song') {
				this.priceError = this.price > 100 || !this.price || this.price < this.song.price;
			}
		}
	},
	computed: {
		...mapGetters({
			userID: 'userID',
			DOWNLOAD_MUSIC_RELEASE_LINK: 'DOWNLOAD_MUSIC_RELEASE_LINK',
			MUSIC_RELEASE_DOWNLOAD_DATA: 'MUSIC_RELEASE_DOWNLOAD_DATA',
			DOWNLOAD_SONG_LINK: 'DOWNLOAD_SONG_LINK',
			SONG_DOWNLOAD_DATA: 'SONG_DOWNLOAD_DATA',
			ARTIST_FOLLOWERS: 'ARTIST_FOLLOWERS',
			HAS_FOLLOWED_ARTIST: 'HAS_FOLLOWED_ARTIST'
		}),
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	},
	mounted() {
		// const inputElement = this.$refs.price;
		//
		// if (inputElement) {
		// 	inputElement.addEventListener('focus', this.disableSafariScroll);
		// 	inputElement.addEventListener('blur', this.enableSafariScroll);
		// }
		this.GET_HAS_FOLLOWED_ARTIST(this.album.artist.id)
			.then(() => {
				if (!this.HAS_FOLLOWED_ARTIST) {
					this.isFollowing = false;
				}
			})
			.catch(err => console.error(`has followed artist, ${err}`));

		this.$nextTick(() => {
			setTimeout(() => {
				this.$refs.price.focus();
			}, 1000);
		});
	},
	beforeDestroy() {
		// const inputElement = this.$refs.price;
		//
		// if (inputElement) {
		// 	inputElement.removeEventListener('focus', this.disableSafariScroll);
		// 	inputElement.removeEventListener('blur', this.enableSafariScroll);
		// }
		//
		// this.enableSafariScroll();
	},

	methods: {
		disableSafariScroll() {
			document.body.style.overflow = 'hidden';
		},

		enableSafariScroll() {
			document.body.style.overflow = '';
		},
		...mapActions({
			addToBasket: 'ADD_TO_BASKET',
			GET_DOWNLOAD_MUSIC_RELEASE_LINK: 'GET_DOWNLOAD_MUSIC_RELEASE_LINK',
			GET_DOWNLOAD_SONG_LINK: 'GET_DOWNLOAD_SONG_LINK',
			GET_ARTIST_FOLLOWERS: 'GET_ARTIST_FOLLOWERS',
			ARTIST_FOLLOW: 'ARTIST_FOLLOW',
			ARTIST_UNFOLLOW: 'ARTIST_UNFOLLOW',
			GET_HAS_FOLLOWED_ARTIST: 'GET_HAS_FOLLOWED_ARTIST',
			checkCanPurchaseMusicRelease: 'CHECK_CAN_PURCHASE_MUSIC_RELEASE',
			checkCanPurchaseTrack: 'CHECK_CAN_PURCHASE_TRACK',
		}),
		closeDialog() {
			this.dialog = false;
		},
		numbersOnly(evt) {
			evt = (evt) ? evt : window.event;
			const charCode = (evt.which) ? evt.which : evt.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				evt.preventDefault();
			} else {
				return true;
			}
		},

		addToCart(ev) {
			if (this.type === 'music_release') {
				if (this.price > 1000 || !this.price || this.price < this.album.price) {
					this.priceError = true;
					return;
				}
			}
			if (this.type === 'song') {
				if (this.price > 100 || !this.price || this.price < this.song.price) {
					this.priceError = true;
					return;
				}
			}

			if (this.priceError === false) {
				if (ev === 'add_to_cart') {
					this.addToCartLoader = true;
				}
				if (ev === 'buy_now') {
					this.buyNowLoader = true;
				}
				this.checkCanPurchaseMusicRelease(this.album.id)
					.then(response => {
						if (response === true) {
							let data = {};

							if (this.type === 'music_release') {
								this.album.inCart = true;

								data.productId = this.album.id;
								data.type = 'music_release';
								data.price = +this.price;
							}
							if (this.type === 'song') {
								this.song.inCart = true;
								data = {
									'productId': this.song.id,
									'type': 'song',
									'price': +this.price
								}
							}
							if (ev === 'add_to_cart') {
								this.closeDialog();
							}
							this.addToBasket(data)
								.then(() => {
									if (ev === 'buy_now') {
										this.$router.push({name: 'checkOut'});
									}
									this.closeDialog();
									this.buyNowLoader = false;
								})
								.catch(err => console.error(`setYourPrice dialog, addToBasket ${err}`));

							if (!this.showFollowBox) {
								this.ARTIST_UNFOLLOW(this.album.artist.id)
									.then(() => {
									})
									.catch(err => console.error(`artist unfollow, ${err}`));
							} else if (!this.isFollowing) {
								this.ARTIST_FOLLOW(this.album.artist.id)
									.then(() => {
									})
									.catch(err => console.error(`artist follow, ${err}`));
							}
						} else if (response === false) {
							this.canPurchaseMusicReleaseDialog = true;
							this.buyNowLoader = false;
							this.addToCartLoader = false;
						}
					})
					.catch(err => {
						console.error(`setYourPrice dialog, checkCanPurchaseMusicRelease ${err}`);
					});
			}
		},
		download() {
			this.downloadLoader = true;
			if (+this.price === 0) {
				if (this.type === 'music_release') {
					this.$store.commit('SET_MUSIC_RELEASE_DOWNLOAD_DATA', {id: this.album.id, title: this.album.title});
					this.GET_DOWNLOAD_MUSIC_RELEASE_LINK(this.MUSIC_RELEASE_DOWNLOAD_DATA.id)
						.then(() => {
							if (this.DOWNLOAD_MUSIC_RELEASE_LINK) {
								let link = document.createElement('a');
								link.href = this.DOWNLOAD_MUSIC_RELEASE_LINK;
								link.download = `${this.MUSIC_RELEASE_DOWNLOAD_DATA.title}`;
								link.click();
								this.downloadLoader = false;
								this.dialog = false;
								this.album.isPurchased = true;
								if (this.album.playList) {
									for (let i = 0; i < this.album.playList.length; i++) {
										this.album.playList[i].song.isPurchased = true;
									}
								}

								this.$store.commit('SET_REFRESH_RELEASES', true);
							} else {
								this.downloadLoader = false;
								this.errorDialog = true;
							}
						})
						.catch(err => {
							console.error(`setYourPrice dialog, download ${err}`);
							this.downloadLoader = false;
							this.errorDialog = true;
						})
				}
				if (this.type === 'song') {
					this.$store.commit('SET_SONG_DOWNLOAD_DATA', {id: this.song.id, title: this.song.title});
					this.GET_DOWNLOAD_SONG_LINK(this.SONG_DOWNLOAD_DATA.id)
						.then(() => {
							if (this.DOWNLOAD_SONG_LINK) {
								let link = document.createElement('a');
								link.href = this.DOWNLOAD_SONG_LINK;
								link.download = `${this.SONG_DOWNLOAD_DATA.title}`;
								link.click();
								this.downloadLoader = false;
								this.dialog = false;
								this.song.isPurchased = true;
								this.$store.commit('SET_REFRESH_RELEASES', true);
							}
						})
						.catch(err => {
							console.error(`setYourPrice dialog, download ${err}`);
							this.downloadLoader = false;
							this.errorDialog = true;
						})
				}
			}
			if (!this.showFollowBox) {
				this.ARTIST_UNFOLLOW(this.album.artist.id)
					.then(() => {
					})
					.catch(err => console.error(`artist unfollow, ${err}`));
			} else if (!this.isFollowing) {
				this.ARTIST_FOLLOW(this.album.artist.id)
					.then(() => {
					})
					.catch(err => console.error(`artist follow, ${err}`));
			}
		},
		toggleFollow() {
			this.showFollowBox = !this.showFollowBox;
		},
	}
}
</script>

<style lang="scss">

</style>
